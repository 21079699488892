












































import { API_PATENT_USER, API_USER_LIST } from "@/configs/Apis";
import commonStore from "@/stores/modules/views/common";
import {
  isEmptyOrWhiteSpace,
  showError,
  showSuccess,
  showWarning,
  showConfirm,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Prop, Vue } from "vue-property-decorator";
import formatters from "@/utils/ColumnFormater";
@Component
export default class PatentUserSelector extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  patentFilter!: any;

  @Prop({ type: String, default: () => "binding" })
  bindingType!: string;

  businessUserQueryData: any = { showAll: false };
  businessUserQueryStr = JSON.stringify({
    userType: "CUSTOMER",
    disabled: false,
  });
  // businessUserQueryStr = "{\"userType\": \"CUSTOMER\"}";
  clearOldUsers = false;

  get colFormatters() {
    return formatters;
  }

  mounted() {
    if (this.bindingType === "binding") {
      this.businessUserQueryStr = JSON.stringify({
        userType: "CUSTOMER",
        disabled: false,
      });
    } else {
      this.businessUserQueryStr = JSON.stringify({
        userType: "CUSTOMER",
        disabled: false,
        patentFilter: this.patentFilter || {}
      });
    }
    this.$store.unregisterModule("businessuser");
    this.$store.registerModule("businessuser", commonStore);
    const columns: Array<TableColumnOption> = [
      {
        prop: "loginName",
        display: "账号",
      },
      {
        prop: "name",
        display: "姓名",
      },
      {
        prop: "enterpriseName",
        display: "所属单位",
      },
      {
        prop: "email",
        display: "邮箱",
      },
      {
        prop: "phone",
        display: "电话",
      },
      {
        prop: "disabled",
        display: "状态",
      },
    ];
    this.applyColumnFormatters(columns);
    this.$store.dispatch("businessuser/setColumns", columns);
  }

  applyColumnFormatters(columns: Array<TableColumnOption>) {
    const fmers = {
      disabled: this.colFormatters.formatUserStatus.bind(this),
      phone: this.colFormatters.formatPhone.bind(this),
      email: this.colFormatters.formatEmail.bind(this),
    };
    if (!columns || !columns.length) {
      return;
    }
    columns.forEach((option) => {
      if (!Object.prototype.hasOwnProperty.call(fmers, option.prop)) {
        return;
      }
      option.formatter = (fmers as any)[option.prop];
    });
  }

  get userDataApi() {
    return API_USER_LIST;
  }

  searchBusinessUser() {
    const queryData: any = this.businessUserQueryData;
    const params: any = { userType: "CUSTOMER", disabled: false };
    if (!isEmptyOrWhiteSpace(queryData.keyword)) {
      params.keyword = queryData.keyword;
    }
    if (queryData.showAll) {
      delete params.disabled;
    }
    if (this.bindingType === "unbinding") {
      params.patentFilter = this.patentFilter || {};
    }
    this.businessUserQueryStr = JSON.stringify(params);
  }

  onAssignBusinessUser() {
    const usergrid = this.$refs.businessusergrid as any;
    const selectedRows = usergrid.getSelectedRows();
    if (!selectedRows || !selectedRows.length) {
      showWarning("请至少选择一个客户");
      return;
    }
    const msg = this.bindingType === "binding" ? "<font color=green>【执行】</font>关联" : "<font color=red><b>【解除】</b></font>关联";
    showConfirm(`是否确认将选中的 ${selectedRows.length} 个客户 ${msg}？`).then(() => {
      Ajax.saveData(
        API_PATENT_USER,
        {
          userIds: selectedRows,
          patentFilter: this.patentFilter,
          clearOldUsers: !!this.clearOldUsers,
        },
        this.bindingType === "binding" ? "PUT" : "DELETE"
      )
        .then(() => {
          showSuccess(this.bindingType === "binding" ? "关联操作完成" : "解除关联操作完成");
          this.$emit("data-success");
        })
        .catch(({ message }) => showError(message));
    });
  }
}
