




























































































import { API_PATENT_EXTRA_FEE } from "@/configs/Apis";
import {
  MSG_ADD_SUCCESS,
  MSG_INVALID_PAGE,
  MSG_MODIFY_SUCCESS
} from "@/configs/Consts";
import { showError, showSuccess, showWarning } from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class PatentExtraFeeForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  patentExtraFeeItem!: any;

  isEdit() {
    return this.patentExtraFeeItem && this.patentExtraFeeItem.id;
  }

  savePatentExtraFee() {
    const form = this.$refs.patentExtraFeeForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const patentData = JSON.parse(JSON.stringify(this.patentExtraFeeItem));
      const isEdit = this.isEdit();
      Ajax.saveData(
        isEdit
          ? `${API_PATENT_EXTRA_FEE}/${this.patentExtraFeeItem.id}`
          : API_PATENT_EXTRA_FEE,
        patentData,
        isEdit ? "PUT" : "POST"
      )
        .then(() => {
          this.$emit("update:patentExtraFeeItem", {});
          showSuccess(isEdit ? MSG_MODIFY_SUCCESS : MSG_ADD_SUCCESS);
          this.$emit("data-success");
        })
        .catch(({ message }) => showError(message));
    });
  }
}
