























































































































































































































































































































































































































































































































































































































import { API_APPLICANT_LIST, API_INVENTOR_LIST, API_PATENT } from "@/configs/Apis";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  showConfirm,
  showError,
  showSuccess,
  showWarning,
} from "@/utils/Common";
import {
  MSG_ADD_SUCCESS,
  MSG_INVALID_PAGE,
  MSG_MODIFY_SUCCESS,
} from "@/configs/Consts";
import { Fetcher as Ajax } from "@/utils/Request";

@Component
export default class PatentForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  patentItem!: any;

  applicants: Array<any> = [];
  inventors: Array<any> = [];

  queryCountry(query: string, cb: any) {
    const allData = this.$store.getters["enums/getEnumsByKey"]("Country");
    const datas: Array<any> = [];
    allData.forEach((data: any) => {
      datas.push({ value: data.label });
    });
    const results = query ? datas.filter((data: any) => {
      return data.value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  queryLegalStatus(query: string, cb: any) {
    const allData = this.$store.getters["enums/getEnumsByKey"]("LegalStatus");
    const datas: Array<any> = [];
    allData.forEach((data: any) => {
      datas.push({ value: data.label });
    });
    const results = query ? datas.filter((data: any) => {
      return data.value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  mounted() {
    this.patentItem.applicantList = this.patentItem.applicantList || [
      {
        name: "",
        address: "",
        contact: "",
        feeRegister: false,
        attorneyPowerCode: "",
        demand: "",
      },
    ];

    this.patentItem.inventorList = this.patentItem.inventorList || [
      {
        name: "",
        nationality: "",
        idCard: "",
      },
    ];

    this.patentItem.patentPriorityList =
      this.patentItem.patentPriorityList || [];

    this.loadAllApplicants();
    this.loadAllInventors();
  }

  loadAllApplicants() {
    Ajax.queryData(API_APPLICANT_LIST, {}, "POST")
      .then((rs: any) => {
        const applicants = rs.data || [];
        const applicantMap: any = {};
        this.applicants = [];
        applicants.forEach((applicant: any) => {
          const key = applicant.name;
          if (!key) {
            return;
          }
          if (!applicantMap[key]) {
            applicantMap[key] = applicant;
            this.applicants.push(applicant);
          } else if (applicant.dataId === this.patentItem.id) {
            applicantMap[key].name = applicant.name;
            applicantMap[key].address = applicant.address;
            applicantMap[key].contact = applicant.contact;
            applicantMap[key].feeRegister = applicant.feeRegister;
            applicantMap[key].attorneyPowerCode = applicant.attorneyPowerCode;
            applicantMap[key].demand = applicant.demand;
          }
        });
        // console.info(this.applicants);
      })
      .catch(() => {
        this.applicants = [];
      });
  }

  loadAllInventors() {
    Ajax.queryData(API_INVENTOR_LIST, {}, "POST")
      .then((rs: any) => {
        const inventors = rs.data || [];
        const inventorMap: any = {};
        this.inventors = [];
        inventors.forEach((inventor: any) => {
          const key = inventor.name;
          if (!key) {
            return;
          }
          if (!inventorMap[key]) {
            inventorMap[key] = inventor;
            this.inventors.push(inventor);
          } else if (inventor.dataId === this.patentItem.id) {
            inventorMap[key].name = inventor.name;
            inventorMap[key].nationality = inventor.nationality;
            inventorMap[key].idCard = inventor.idCard;
          }
        });
      })
      .catch(() => {
        this.inventors = [];
      });
  }

  queryApplicant(query: string, cb: any) {
    const datas: Array<any> = [];
    this.applicants.forEach((data: any) => {
      datas.push(JSON.parse(JSON.stringify(data)));
    });
    const results = query ? datas.filter((data: any) => {
      return data.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    }) : datas;
    cb(results);
  }

  onApplicantChange(applicant: any) {
    const patentApplicant = this.patentItem.applicantList.find(
      (appt: any) => applicant.name === appt.name
    );
    if (!patentApplicant) {
      return;
    }
    patentApplicant.name = applicant.name;
    patentApplicant.address = applicant.address;
    patentApplicant.contact = applicant.contact;
    patentApplicant.feeRegister = applicant.feeRegister;
    patentApplicant.attorneyPowerCode = applicant.attorneyPowerCode;
    patentApplicant.demand = applicant.demand;
  }

  queryInventor(query: string, cb: any) {
    const datas: Array<any> = [];
    this.inventors.forEach((data: any) => {
      datas.push(JSON.parse(JSON.stringify(data)));
    });
    const results = query ? datas.filter((data: any) => {
      return data.name.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  onInventorChange(inventor: any) {
    const patentInventor = this.patentItem.inventorList.find(
      (appt: any) => inventor.name === appt.name
    );
    if (!patentInventor) {
      return;
    }
    patentInventor.name = inventor.name;
    patentInventor.nationality = inventor.nationality;
    patentInventor.idCard = inventor.idCard;
  }

  isEditPatent() {
    return this.patentItem && this.patentItem.id;
  }

  get isPct() {
    return this.patentItem.appType === "PCT";
  }

  addApplicantRow(idx: number) {
    if (idx === -1) {
      this.patentItem.applicantList.push({
        clientId: "",
        name: "",
        address: "",
        contact: "",
        feeRegister: false,
        attorneyPowerCode: "",
        demand: "",
      });
      return;
    }
    this.patentItem.applicantList.splice(idx + 1, 0, {
      clientId: "",
      name: "",
      address: "",
      contact: "",
      feeRegister: false,
      attorneyPowerCode: "",
      demand: "",
    });
  }

  deleteApplicantRow(idx: number, name: string) {
    // if (this.patentItem.applicantList.length === 1) {
    //   showError("请至少保留一条数据");
    //   return;
    // }
    showConfirm(`确认移除 ${name} ？`)
      .then(() => {
        this.patentItem.applicantList.splice(idx, 1);
      })
      .catch(() => "");
  }

  addInventorRow(idx: number) {
    if (idx === -1) {
      this.patentItem.inventorList.push({
        clientId: "",
        name: "",
        nationality: "",
        idCard: "",
      });
      return;
    }
    this.patentItem.inventorList.splice(idx + 1, 0, {
      clientId: "",
      name: "",
      nationality: "",
      idCard: "",
    });
  }

  deleteInventorRow(idx: number, name: string) {
    // if (this.patentItem.inventorList.length === 1) {
    //   showError("请至少保留一条数据");
    //   return;
    // }
    showConfirm(`确认移除 ${name} ？`)
      .then(() => {
        this.patentItem.inventorList.splice(idx, 1);
      })
      .catch(() => "");
  }

  addPriorityRow(idx: number) {
    if (idx === -1) {
      this.patentItem.patentPriorityList.push({
        priorityAppNo: "",
        priorityAppType: "",
        priorityAppCountry: "",
        priorityAppDate: "",
      });
      return;
    }
    this.patentItem.patentPriorityList.splice(idx + 1, 0, {
      priorityAppNo: "",
      priorityAppType: "",
      priorityAppCountry: "",
      priorityAppDate: "",
    });
  }

  deletePriorityRow(idx: number, name: string) {
    // if (this.patentItem.patentPriorityList.length === 1) {
    //   showError("请至少保留一条数据");
    //   return;
    // }
    showConfirm(`确认移除 ${name} ？`)
      .then(() => {
        this.patentItem.patentPriorityList.splice(idx, 1);
      })
      .catch(() => "");
  }

  savePatent() {
    const form = this.$refs.patentForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const patentData = JSON.parse(JSON.stringify(this.patentItem));
      patentData.text = patentData.text || {};
      const patentPriorityList = patentData.patentPriorityList || [];
      patentData.patentPriorityList = [];
      patentPriorityList.forEach((patentPriority: any) => {
        if (!patentPriority.priorityAppDate) {
          delete patentPriority.priorityAppDate;
        }
        patentData.patentPriorityList.push(patentPriority);
      });
      // if (!isEmptyOrWhiteSpace(patentData.abstractInfo)) {
      //   patentData.text.abstractInfo = patentData.abstractInfo;
      //   delete patentData.abstractInfo;
      // }
      // if (!isEmptyOrWhiteSpace(patentData.claims)) {
      //   patentData.text.claims = patentData.claims;
      //   delete patentData.claims;
      // }
      const isEdit = this.isEditPatent();
      Ajax.saveData(
        isEdit ? `${API_PATENT}/${this.patentItem.id}` : API_PATENT,
        patentData,
        isEdit ? "PUT" : "POST"
      ).then(() => {
        this.$emit("update:patentItem", {});
        showSuccess(
          this.isEditPatent() ? MSG_MODIFY_SUCCESS : MSG_ADD_SUCCESS
        );
        this.$emit("data-success");

        // reload enums
        this.$store.dispatch("enums/getEnums");
      })
      .catch(({ message }) => showError(message));
    });
  }
}
