














































































































































































import { API_PATENT_FILE, API_UPLOAD_PATENT_FILE } from "@/configs/Apis";
import {
  MSG_ADD_SUCCESS,
  MSG_INVALID_PAGE,
  MSG_MODIFY_SUCCESS
} from "@/configs/Consts";
import {
  showError,
  showSuccess,
  showWarning
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class PatentFileForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  patentFileItem!: any;

  fileList = [];

  queryFileClass(query: string, cb: any) {
    const allData = this.$store.getters["enums/getEnumsByKey"]("FileClass");
    const datas: Array<any> = [];
    allData.forEach((data: any) => {
      datas.push({ value: data.label });
    });
    const results = query ? datas.filter((data: any) => {
      return data.value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  queryStatus(query: string, cb: any) {
    const allData = this.$store.getters["enums/getEnumsByKey"]("FileStatus");
    const datas: Array<any> = [];
    allData.forEach((data: any) => {
      datas.push({ value: data.label });
    });
    const results = query ? datas.filter((data: any) => {
      return data.value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    }) : datas;
    cb(results);
  }

  mounted() {
    this.fileList = JSON.parse(this.patentFileItem.files || "[]");
  }

  get uploadAction() {
    return Ajax.formatApiUrl(API_UPLOAD_PATENT_FILE, {});
  }

  handlePreview(file: any) {
    const url = file.url || file.response.location;
    if (url) {
      window.open(url);
    }
  }

  handleUploadSuccess(res: any, file: any) {
    const fileList = JSON.parse(this.patentFileItem.files || "[]");
    fileList.push({ name: file.raw.name, url: file.response.location, size: file.size });
    this.patentFileItem.files = JSON.stringify(fileList);
    showSuccess("上传成功");
  }

  handleRemove(file: any, fileList: any) {
    const newFileList = fileList.map((fn: any) => {
      return {
        name: fn.name || fn.raw.name,
        url: fn.url || fn.response.location,
        size: fn.size
      };
    });
    this.patentFileItem.files = JSON.stringify(newFileList);
  }

  isEdit() {
    return this.patentFileItem && this.patentFileItem.id;
  }

  savePatentFile() {
    const form = this.$refs.patentFileForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const patentData = JSON.parse(JSON.stringify(this.patentFileItem));
      const isEdit = this.isEdit();
      Ajax.saveData(
        isEdit
          ? `${API_PATENT_FILE}/${this.patentFileItem.id}`
          : API_PATENT_FILE,
        patentData,
        isEdit ? "PUT" : "POST"
      )
        .then(() => {
          this.$emit("update:patentFileItem", {});
          showSuccess(isEdit ? MSG_MODIFY_SUCCESS : MSG_ADD_SUCCESS);
          this.$emit("data-success");

          // reload enums
          this.$store.dispatch("enums/getEnums");
        })
        .catch(({ message }) => showError(message));
    });
  }
}
